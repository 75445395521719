import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import './assets/css/main.css'  
import axios from 'axios'
import VueToastr from 'vue-toastr'
import VuetifyConfirm from 'vuetify-confirm'
import VueRouter from 'vue-router'
import AppHome from './components/AppHome'
import AppSecrets from './components/AppSecrets'
import AppDocuments from './components/AppDocuments'
import AppBankAccounts from './components/AppBankAccounts'
import AppTfis from './components/AppTfis'
import AppAssets from './components/AppAssets'
import AppLogin from './components/AppLogin'
import AppSummary from './components/AppSummary'

Vue.config.productionTip = false
Vue.use(VueToastr, {
  defaultPosition: 'toast-top-right',
  defaultClassNames: [ 'my-toast']
})
Vue.use(VuetifyConfirm, { 
  vuetify,
  buttonTrueText: 'OK',
  buttonFalseText: 'Anuluj'
 })

 Vue.use(VueRouter)
 const routes = [
   { path: '/', component: AppHome, name: 'Home' },
   { path: '/secrets', component: AppSecrets, name: 'Secrets'},
   { path: '/documents', component: AppDocuments, name: 'Documents'},
   { path: '/accounts', component: AppBankAccounts, name: 'Accounts'},
   { path: '/tfis', component: AppTfis, name: 'TFI'},
   { path: '/assets', component: AppAssets, name: 'Assets'},
   { path: '/login', component: AppLogin, name: 'Login'},
   { path: '/summary', component: AppSummary, name: 'Summary'}
 ]
const router = new VueRouter({
  routes
})


axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.interceptors.response.use(undefined, error => {
  if (error.response.status === 401) {
        window.location.href = '#/login';
  } 
  else {
    return Promise.reject(error)
  } 
});

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
