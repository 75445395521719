import axios from "axios"
import MockAdapter from "axios-mock-adapter"

const api = {
    secrets: {
        get: (searchTerm) => {
            return axios.get(`/secrets/${searchTerm}`)
        },
        decrypt: (id, pwd) => {
            return axios.post(`/secrets/decrypt/${id}`, { pwd: pwd })
        },
        save: (secret) => {
            return axios.post('/secrets', secret)
        },
        delete: (id) => {
            return axios.delete(`/secrets/${id}`)
        }
    },
    documents: {
        getCategories: () => {
            return axios.get('/documents/categories')
        },
        getList: (filter) => {
            const searchParams = new URLSearchParams()
            if (filter.name) {
                searchParams.append("name", filter.name)
            }
            if (filter.category) {
                searchParams.append("category", filter.category)
            }
            searchParams.append("page", filter.page)
            searchParams.append("count", filter.count)
            return axios.get('/documents' + '?' + searchParams.toString())
        },
        getDocument: (id) => {
            return axios.get('/documents/' + id)
        },
        save: (files, data) => {
            const formData = new FormData()
            if (files && files.length > 0) {
                files.forEach((file) => {
                    formData.append('Files', file)
                })
            }
            formData.append('Document', JSON.stringify(data))

            return axios.post('/documents', formData)
        },
        remove: (id) => {
            return axios.delete('/documents/' + id)
        }
    },
    bankAccounts: {
        getList: (onlyActive) => {
            const searchParams = new URLSearchParams()
            searchParams.append("onlyActive", onlyActive)
            return axios.get('/bankAccounts' + '?' + searchParams.toString())
        },
        getById: (id) => {
            return axios.get('/bankAccounts/' + id)
        },
        save: (item) => {
            return axios.post('/bankAccounts', item)
        },
        delete: (id) => {
            return axios.delete('/bankAccounts/' + id)
        }
    },
    tfis: {
        getList: () => {
            return axios.get('/tfis')
        },
        getById: (id) => {
            return axios.get('/tfis/' + id)
        },
        save: (item) => {
            return axios.post('/tfis', item)
        },
        delete: (id) => {
            return axios.delete('/tfis/' + id)
        }
    },
    assets: {
        getList: () => {
            return axios.get('/assets')
        },
        getById: (id) => {
            return axios.get('/assets/' + id)
        },
        save: (item) => {
            return axios.post('/assets', item)
        },
        delete: (id) => {
            return axios.delete('/assets/' + id)
        }
    },
    auth: {
        username: () => {
            return axios.get('/auth/user')
        }
    },
    summary: {
        current: () => {
            return axios.get('/summary')
        },
        snapshots: (filter) => {
            const searchParams = new URLSearchParams()
            searchParams.append('from', filter.from)
            searchParams.append('to', filter.to)
            searchParams.append('page', filter.page)
            searchParams.append('count', filter.count)
            return axios.get('/summary/snapshots?' + searchParams.toString())
        },
        getById: (id) => {
            return axios.get('/summary/snapshots/' + id)
        },
        create: (snapshots) => {
            return axios.post('/summary/snapshots', snapshots[0])
        },
        delete: (id) => {
            return axios.delete('/summary/snapshots/' + id)
        }
    }
}

if (process.env.VUE_APP_MOCK_API === "1") {
    const mock = new MockAdapter(axios, { delayResponse: 300 })
    mock.onGet(new RegExp('/secrets/*')).reply(200, [
        { name: 'secret1', encrypted: false, pwd: 'baba' },
        { name: 'secret2', encrypted: true, pwd: 'sdfweegdofo' }
    ])

    mock.onPost(new RegExp('/secrets/decrypt/*'), 123).reply(200, "tajne-hasło")
    mock.onPost(new RegExp('/secrets/decrypt/*')).reply(400)
    mock.onPost('/secrets').reply(200)
    mock.onDelete(new RegExp('/secrets/*')).reply(200)

    mock.onGet('/documents/categories').reply(200, [
        { id: 1, name: 'Kategoria1' },
        { id: 2, name: 'Kategoria2' }
    ])

    mock.onGet(new RegExp("/documents/\\d+$")).reply(200, {
        id: 3,
        category: 1,
        name: 'From server doc',
        fields: [
            { id: 1, name: 'Buka', value: '12312332' }
        ],
        files: [
            { id: 1, name: 'small_file.png', created: 'Wczoraj o 12:33', size: '12.34 kB' }
        ]
    })

    mock.onGet(new RegExp('/documents*')).reply(200, {
        total: 12,
        items: [
            { id: 1, name: 'Doc1', category: 'Kategoria 1', lastAccess: 'dzisiaj 00:00:12' },
            { id: 2, name: 'Doc2', category: 'Kategoria 1', lastAccess: 'dzisiaj 00:00:12' },
            { id: 3, name: 'Doc3', category: 'Kategoria 1', lastAccess: 'dzisiaj 00:00:12' },
            { id: 4, name: 'Doc4', category: 'Kategoria 1', lastAccess: 'dzisiaj 00:00:12' },
            { id: 5, name: 'Doc5', category: 'Kategoria 1', lastAccess: 'dzisiaj 00:00:12' },
            { id: 6, name: 'Doc6 wieloczłonowy', category: 'Kategoria 1', lastAccess: 'dzisiaj 00:00:12' },
            { id: 7, name: 'Doc7', category: 'Kategoria 1', lastAccess: 'dzisiaj 00:00:12' },
            { id: 8, name: 'Doc8', category: 'Kategoria 1', lastAccess: 'dzisiaj 00:00:12' },
            { id: 9, name: 'Doc9', category: 'Kategoria 1', lastAccess: 'dzisiaj 00:00:12' },
            { id: 10, name: 'Doc10 również dość długi', category: 'Kategoria 1', lastAccess: 'dzisiaj 00:00:12' },
            { id: 11, name: 'Doc11', category: 'Kategoria 1', lastAccess: 'dzisiaj 00:00:12' },
            { id: 12, name: 'Doc12 bardzo długi', category: 'Kategoria 1', lastAccess: 'dzisiaj 00:00:12' },
        ]
    })

    mock.onPost('/documents').reply(200, 22)
    mock.onDelete(new RegExp('/documents/*')).reply(200)

    mock.onGet('/bankAccounts?onlyActive=true').reply(200, {
        items: [
            { id: 1, name: 'Rachunek 1', bank: 'MBank', amount: '237 342,34 PLN', account: '25 1140 2004 0000 3302 4703 5583' },
            { id: 2, name: 'Rachunek EUR', bank: 'MBank', amount: '34 542,34 EUR', account: '25 1140 2004 0000 3302 4703 5543' },
            { id: 3, name: 'Rachunek A', bank: 'Alior', amount: '101 324,01 PLN', account: '19 2490 0005 0000 4100 6608 7593' },
        ],
        total: 3,
        totalAmount: '589 342,03 PLN'
    })
    mock.onGet(new RegExp('/bankAccounts/*')).reply(200, {
        id: 2,
        name: 'Rachunek EUR',
        bank: 'MBank',
        amount: 34542.34,
        currency: 'EUR',
        account: '25 1140 2004 0000 3302 4703 5543',
        active: true
    })
    mock.onPost('/bankAccounts').reply(200)
    mock.onDelete(new RegExp('/bankAccounts/*')).reply(200)

    mock.onGet('/tfis').reply(200, [
        { id: 1, name: 'Testowy fundusz akcji', bank: 'MBank', type: 'akcji', amount: 5848.45 },
        { id: 2, name: 'Testowy fundusz hybrydowy', bank: 'MBank', type: 'hybrydowy', amount: 7448.45 },
        { id: 3, name: 'Testowy fundusz obligacji', bank: 'MBank', type: 'obligacji', amount: 1456.45 }
    ])
    mock.onGet('/tfis/1').reply(200, {
        id: 1,
        name: 'Testowy fundusz akcji',
        bank: 'MBank',
        type: 1,
        unitsCount: 23.456,
        unitPrice: 120.324
    })
    mock.onPost('/tfis').reply(204)
    mock.onDelete('/tfis/1').reply(204)

    mock.onGet('/assets').reply(200, [
        { id: 1, name: 'BTC', category: 'Krypto', amount: 394934 },
        { id: 2, name: 'ETH', category: 'Krypto', amount: 4934 }
    ])
    mock.onGet('/assets/1').reply(200, {
        id: 1, name: 'BTC', category: 'Krypto', unitsCount: 2, unitPrice: 32423.34
    })
    mock.onPost('/assets').reply(204)
    mock.onDelete('/assets/1').reply(204)

    mock.onGet('/auth/user').reply(200, "Maciek")

    mock.onGet('/summary').reply(200, [{
        id: '0',
        title: 'RAZEM',
        amount: 1003234.34,
        count: 1,
        children: [
            {
                id: '0/1',
                title: 'Rachunki',
                amount: 567456.34,
                count: 2,
                children: [
                    {
                        id: '0/1/1',
                        title: 'E-konto',
                        amount: 43545.56,
                        count: 1
                    },
                    {
                        id: '0/1/2',
                        title: 'EUR konto',
                        amount: 243543.45,
                        count: 1
                    }
                ]
            },
            {
                id: '0/2',
                title: 'TFI',
                amount: 192344.34,
                count: 3
            }
        ]
    }])

    mock.onGet('/summary/snapshots/1').reply(200, {
        time: '2022-04-22',
        id: 1,
        data: [{
            id: 1,
            title: 'RAZEM',
            amount: 1503234.34,
            count: 1,
            children: [
                {
                    id: '0/1',
                    title: 'Rachunki',
                    amount: 27456.34,
                    count: 2,
                    children: [
                        {
                            id: '0/1/1',
                            title: 'E-konto',
                            amount: 43545.56,
                            count: 1
                        },
                        {
                            id: '0/1/2',
                            title: 'EUR konto',
                            amount: 243543.45,
                            count: 1
                        }
                    ]
                },
                {
                    id: '0/2',
                    title: 'TFI',
                    amount: 192344.34,
                    count: 3
                }
            ]
        }]
    })

    mock.onGet(new RegExp('/summary/snapshots*')).reply(200,
        {
            total: 2,
            items: [
                { id: 1, time: '2021-03-23' },
                { id: 2, time: '2022-04-23' },
            ]
        }
    )

    mock.onPost('/summary/snapshots').reply(204)

    mock.onDelete('/summary/snapshots/1').reply(204)
}

export default api