<template>
  <v-layout align-start wrap>
    <v-flex xs12 md6 class="padding" id="list">
      <v-card>
        <v-card-title>Wyszukiwanie dokumentów</v-card-title>
        <v-card-text>
          <v-layout align-baseline>
            <v-flex xs12 md6>
              <v-text-field
                class="padding"
                label="Nazwa"
                v-model="filter.name"
                @input="debouncedGetList"
                ref="listName"
              />
            </v-flex>
            <v-flex xs12 md6>
              <v-select
                class="padding"
                label="Kategoria"
                :loading="dict.loading"
                :items="categoriesForList"
                item-text="name"
                item-value="id"
                v-model="filter.category"
                @change="getList"
              ></v-select>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-text>
          <v-data-table
            :headers="list.headers"
            :loading="list.loading"
            :items="list.items"
            disable-sort
            class="elevation-1"
            :server-items-length="list.total"
            :options.sync="listOptions"
            @click:row="selectRow"
          ></v-data-table>
        </v-card-text>
        <v-card-text>
          <v-btn color="primary" @click="addNewDoc"> Dodaj nowy </v-btn>
        </v-card-text>
      </v-card>
    </v-flex>
    <v-flex xs12 md6 class="padding">
      <v-card v-if="selectedDoc" :loading="selectedDoc.loading">
        <v-skeleton-loader
          v-if="selectedDoc.loading"
          type="card, article, actions"
        >
        </v-skeleton-loader>
        <template v-else>
          <v-card-title>
            <v-text-field
              :rules="validationRules"
              label="Nazwa"
              v-model="selectedDoc.data.name"
              placeholder="Wpisz nazwę..."
              ref="docName"
            />
          </v-card-title>
          <v-card-text>
            <v-select
              label="Kategoria"
              v-model="selectedDoc.data.category"
              item-text="name"
              item-value="id"
              :items="categoriesForDoc"
            >
            </v-select>
            <v-text-field
              v-if="selectedDoc.data.category === 0"
              label="Nowa nazwa kategorii"
              v-model="selectedDoc.data.newCategory"
              :rules="validationRules"
            />
          </v-card-text>
          <v-card-text>
            <h3>Pliki</h3>
            <v-simple-table dense>
              <template>
                <thead>
                  <tr>
                    <th>Nazwa</th>
                    <th>Dodano</th>
                    <th>Rozmiar</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(file, index) in selectedDoc.data.files"
                    :key="file.id"
                  >
                    <td>{{ file.name }}</td>
                    <td>{{ file.created }}</td>
                    <td>{{ file.size }}</td>
                    <td>
                      <a
                        :href="
                          apiUrl + '/documents/files/' + file.id + '/preview'
                        "
                        target="_blank"
                        ><v-icon large color="primary">mdi-file-find</v-icon></a
                      >
                      <a
                        :href="
                          apiUrl + '/documents/files/' + file.id + '/download'
                        "
                        ><v-icon large>mdi-file-download</v-icon></a
                      >
                      <v-icon
                        large
                        color="error"
                        @click="() => removeFile(index)"
                      >
                        mdi-file-cancel
                      </v-icon>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="3">
                      <v-file-input
                        multiple
                        show-size
                        @change="selectFiles"
                      ></v-file-input>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>

          <v-card-text>
            <h3>Dodatkowe pola</h3>
            <v-simple-table>
              <template>
                <thead>
                  <tr>
                    <th>Nazwa</th>
                    <th>Wartość</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in selectedDoc.data.fields"
                    :key="index"
                  >
                    <td>
                      <v-text-field
                        v-model="item.name"
                        :rules="validationRules"
                      />
                    </td>
                    <td>
                      <v-text-field
                        v-model="item.value"
                        :rules="validationRules"
                      />
                    </td>
                    <td>
                      <v-btn
                        small
                        color="error"
                        @click="() => removeField(item)"
                      >
                        <v-icon> mdi-delete </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="3">
                      <v-btn small color="primary" @click="addNewField">
                        Dodaj
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
          <v-card-text>
            <v-textarea v-model="selectedDoc.data.description" label="Opis">
            </v-textarea>
          </v-card-text>
          <v-card-text>
              <span class="marginRight">
                  Utworzono: <b>{{selectedDoc.data.created}}</b>
              </span>
              <span class="marginRight">
                  Edytowano: <b>{{selectedDoc.data.updated}}</b>
              </span>
              <span>
                  Ostatnio otwarto: <b>{{selectedDoc.data.lastAccess}}</b>
              </span>
          </v-card-text>
        </template>
        <v-card-actions>
          <v-btn color="primary" @click="save" :loading="selectedDoc.saving">
            Zapisz
          </v-btn>
          <v-btn color="error" @click="remove" :loading="selectedDoc.saving">
            Usuń
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</template>
<style scoped>
.padding {
  padding: 3px;
}
.marginRight {
  margin-right: 5px;
}
</style>

<script>
import api from "../api";
import Vue from "vue";
import rules from '../helpers/componentRules'

export default {
  data() {
    return {
      filter: {
        name: "",
        category: "",
      },
      dict: {
        loading: true,
        categories: [],
      },
      list: {
        loading: true,
        total: 0,
        headers: [
          { text: "Nazwa", value: "name" },
          { text: "Kategoria", value: "category" },
          { text: 'Utworzony', value: 'created'},
          { text: "Ostatnio otwarty", value: "lastAccess" },
        ],
        items: [],
      },
      listOptions: {},
      selectedDoc: null,
      validationRules: [rules.notEmpty],
    };
  },
  watch: {
    listOptions: {
      handler() {
        this.getList();
      },
      deep: true,
    },
    selectedDoc() {
      if (this.selectedDoc && this.selectedDoc.data) {
        Vue.nextTick(() => {
          this.$refs.docName.focus();
        });
      }
    },
  },
  computed: {
    categoriesForDoc() {
      return [{ id: 0, name: "(nowa)" }, ...this.dict.categories];
    },
    categoriesForList() {
      return [{ id: null, name: "(wszystkie)" }, ...this.dict.categories];
    },
    apiUrl() {
      return process.env.VUE_APP_API_URL;
    },
  },
  async mounted() {
    try {
      const categories = await api.documents.getCategories();
      this.dict.categories = categories.data;
    } catch (error) {
      this.$toastr.e("Nie udało się pobrać kategorii");
    } finally {
      this.dict.loading = false;
    }
  },
  methods: {
    async getList() {
      this.list.loading = true;
      try {
        const response = await api.documents.getList({
          ...this.filter,
          page: this.listOptions.page,
          count: this.listOptions.itemsPerPage,
        });
        const data = response.data;
        this.list.total = data.total;
        this.list.items = data.items.map((original) => {
          return {
            ...original,
            category: this.dict.categories.find(
              (c) => c.id === original.category
            ).name,
          };
        });
      } catch (e) {
        this.$toastr.e("Nie udało się pobrać elementów z listy");
        console.log(e);
      } finally {
        this.list.loading = false;
      }
    },
    addNewDoc() {
      this.selectedDoc = {
        loading: false,
        data: {
          id: null,
          name: "",
          category: 0,
          fields: [],
        },
      };
    },
    async selectRow(row) {
      this.selectedDoc = {
        loading: true,
      };
      try {
        const response = await api.documents.getDocument(row.id);
        this.selectedDoc = { data: response.data, loading: false };
        var list = this.$el.querySelector("#list");
        list.scrollTop = list.scrollHeight;
      } catch (e) {
        console.log(e);
        this.$toastr.e("Nie udało się pobrać dokumentu z serwera");
        this.selectedDoc = null;
      }
    },
    debouncedGetList() {
      clearTimeout(this.listTimer);
      this.listTimer = setTimeout(this.getList, 500);
    },
    addNewField() {
      this.selectedDoc.data.fields.push({
        id: 0,
        name: "",
        value: "",
      });
    },
    async removeField(field) {
      const index = this.selectedDoc.data.fields.indexOf(field);
      if (field.id === 0) {
        this.selectedDoc.data.fields.splice(index, 1);
      } else {
        const res = await this.$confirm("Na pewno chcesz usunąć to pole?");
        if (res) {
          this.selectedDoc.data.fields.splice(index, 1);
        }
      }
    },
    selectFiles(files) {
      this.selectedDoc.uploads = files;
    },
    async removeFile(index) {
      if (await this.$confirm("Na pewno chcesz usunąć ten plik?")) {
        this.selectedDoc.data.files.splice(index, 1);
      }
    },
    async save() {
      this.selectedDoc.saving = true;
      try {
        const response = await api.documents.save(
          this.selectedDoc.uploads,
          this.selectedDoc.data
        );
        this.$toastr.s("Zapisano pomyślnie");
        if (response.data.refreshCategories) {
          await this.refreshCategories();
        }
        this.selectedDoc = null;
        this.getList();
        this.$refs.listName.focus();
      } catch (e) {
        console.error(e);
        this.$toastr.e("Nie udało się zapisać");
      } finally {
        this.selectedDoc.saving = false;
      }
    },
    async refreshCategories() {
      try {
        this.dict.loading = true;
        const newCategories = await api.documents.getCategories();
        this.dict.categories = newCategories.data;
      } catch (e) {
        console.error(e);
        this.$toastr.e("Nie udało się pobrać kategorii");
      } finally {
        this.dict.loading = false;
      }
    },
    async remove() {
      if (this.selectedDoc.data.id === 0) {
        this.selectedDoc = null;
        return;
      }
      if (await this.$confirm("Czu na pewno chcesz usunąć ten dokument?")) {
        try {
          const resp = await api.documents.remove(this.selectedDoc.data.id);
          this.$toastr.s("Usunięto pomyślnie");
          this.selectedDoc = null;
          if (resp.data.refreshCategories) {
            await this.refreshCategories();
          }
          await this.getList();
        } catch (e) {
          console.error(e);
          this.$toastr.e("Nie udało się usunąć");
        }
      }
    },
  },
};
</script>
