<template>
  <v-layout align-start wrap>
    <v-flex xs12 md6 class="padding3">
      <v-card>
        <v-card-title>Rachunki bankowe</v-card-title>
        <v-card-text>
          <v-checkbox v-model="onlyActive" label="Tylko aktywne" />
        </v-card-text>
        <v-card-text>
          <v-data-table
            :headers="list.headers"
            :loading="list.loading"
            :items="list.items"
            class="elevation-1"
            item-key="id"
            sort-by="name"
            group-by="bank"
            hide-default-footer
            @click:row="selectRow"
          ></v-data-table>
        </v-card-text>
        <v-card-text class="alignRight">
          <h2>RAZEM: {{ list.totalAmount }}</h2>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="addNew">Dodaj nowy</v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
    <v-flex xs12 md6 class="padding3">
      <v-card v-if="selectedRow" :loading="selectedRow.loading">
        <v-skeleton-loader
          v-if="selectedRow.loading"
          type="card, article, actions"
        >
        </v-skeleton-loader>
        <template v-else>
          <v-card-title>
            <v-text-field
              prepend-icon="mdi-form-textbox"
              label="Nazwa"
              v-model="selectedRow.data.name"
              placeholder="Wpisz nazwę..."
              ref="docName"
              :rules="validationRules.name"
            />
          </v-card-title>
          <v-card-text>
            <v-text-field
              prepend-icon="mdi-bank"
              label="Bank"
              v-model="selectedRow.data.bank"
              placeholder="Wpisz bank..."
              :rules="validationRules.bank"
            />
          </v-card-text>
          <v-card-text>
            <v-layout align-baseline>
              <v-flex xs9>
                <v-text-field
                  prepend-icon="mdi-piggy-bank"
                  label="Rachunek"
                  ref="account"
                  v-model="selectedRow.data.account"
                  placeholder="Wpisz rachunek..."
                  :rules="validationRules.account"
                  v-on:focus="$event.target.select()"
                />
              </v-flex>
              <v-flex>
                <v-btn small color="secondary" @click="copyToClipboard">
                  <v-icon title="Kopiuj"> mdi-content-copy </v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-text>
            <v-layout align-baseline>
              <v-flex xs6>
                <v-text-field
                  prepend-icon="mdi-cash-multiple"
                  label="Kwota"
                  v-model="selectedRow.data.amount"
                  placeholder="Wpisz kwotę"
                  type="number"
                />
              </v-flex>
              <v-flex>
                <v-text-field
                  prepend-icon="mdi-currency-usd"
                  label="Waluta"
                  v-model="selectedRow.data.currency"
                  placeholder="Wpisz walutę"
                  :rules="validationRules.currency"
                />
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-text>
            <v-checkbox
              v-model="selectedRow.data.active"
              label="Aktywny"
            ></v-checkbox>
          </v-card-text>
          <v-card-text>
              <span class="marginRight">
                  Utworzono: <b>{{selectedRow.data.createdPretty}}</b>
              </span>
              <span class="marginRight">
                  Edytowano: <b>{{selectedRow.data.updatedPretty}}</b>
              </span>
          </v-card-text>
        </template>
        <v-card-actions>
          <v-btn color="primary" @click="save" :loading="selectedRow.saving">
            Zapisz
          </v-btn>
          <v-btn color="error" @click="remove" :loading="selectedRow.saving">
            Usuń
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</template>
<script>
import api from "../api";
import Vue from "vue";
import rules from "../helpers/componentRules";

export default {
  data() {
    return {
      onlyActive: true,
      list: {
        loading: true,
        total: 0,
        totalAmount: null,
        headers: [
          { text: "Nazwa", value: "name" },
          { text: "Bank", value: "bank" },
          { text: "Rachunek", value: "account" },
          { text: "Środki", value: "amount", align: "end" },
        ],
        items: [],
      },
      listOptions: {},
      selectedRow: null,
      validationRules: {
        name: [rules.notEmpty, rules.maxNlength(30)],
        bank: [rules.notEmpty, rules.maxNlength(20)],
        account: [rules.notEmpty, rules.maxNlength(40)],
        currency: [rules.notEmpty, rules.exactLength(3)],
      },
    };
  },
  watch: {
    onlyActive() {
      this.getList();
    },
    selectedRow() {
      if (this.selectedRow && this.selectedRow.data) {
        Vue.nextTick(() => {
          this.$refs.docName.focus();
        });
      }
    },
  },
  async mounted() {
    await this.getList();
  },
  methods: {
    async getList() {
      this.list.loading = true;
      try {
        const response = await api.bankAccounts.getList(this.onlyActive);
        this.list.items = response.data.items;
        this.list.totalAmount = response.data.totalAmount;
      } catch (err) {
        console.error(err);
        this.$toastr.e("Nie udało się pobrać listy rachunków");
      } finally {
        this.list.loading = false;
      }
    },
    async selectRow(row) {
      this.selectedRow = {
        loading: true,
      };
      try {
        const response = await api.bankAccounts.getById(row.id);
        this.selectedRow = { data: response.data, loading: false };
      } catch (e) {
        console.log(e);
        this.$toastr.e("Nie udało się pobrać dokumentu z serwera");
        this.selectedRow = null;
      }
    },
    addNew() {
      this.selectedRow = {
        loading: false,
        data: {
          id: 0,
          name: "",
          bank: "",
          account: "",
          currency: "PLN",
          amount: 0,
          active: true,
        },
      };
    },
    async save() {
      this.selectedRow.saving = true;
      try {
        await api.bankAccounts.save(this.selectedRow.data);
        this.$toastr.s("Zapisano pomyślnie");
        this.selectedRow = null;
        this.getList();
      } catch (e) {
        console.error(e);
        this.$toastr.e("Nie udało się zapisać");
      }
    },
    async remove() {
      if (await this.$confirm("Czy na pewno chcesz usunąć trwale ten rachunek?")) {
        this.selectedRow.saving = true;
        try {
          await api.bankAccounts.delete(this.selectedRow.data.id);
          this.$toastr.s("Usunięto pomyślnie");
          this.selectedRow = null;
          this.getList();
        } catch (e) {
          console.error(e);
          this.$toastr.e("Nie udało się usunąć");
        }
      }
    },
    copyToClipboard() {
      this.$refs.account.focus();
      document.execCommand("copy");
      this.$toastr.s("Skopiowano");
    },
  },
};
</script>