export default {
    notEmpty: (value) => !!value || "Pole wymagane",
    maxNlength: (length) => value => {
        if (!value) {
            return false
        }
        if (value.length > length) {
            return `Pole za długie (max.${length} znaków)`
        }
        return true
    },
    exactLength: (length) => value => {
        if (!value) return false
        if (value.length !== length) {
            return `Pole powinno mieć dokładnie ${length} znaków`
        }
        return true
    }
}