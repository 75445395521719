<template>
  <v-layout align-start wrap>
    <v-flex xs12 md6 class="padding3">
      <v-card>
        <v-card-title>Inne passywa</v-card-title>
        <v-card-text>
          <v-data-table
            :headers="list.headers"
            :loading="list.loading"
            :items="list.items"
            class="elevation-1"
            item-key="id"
            sort-by="name"
            group-by="category"
            hide-default-footer
            @click:row="selectRow"
          ></v-data-table>
        </v-card-text>
        <v-card-text class="alignRight">
          <h2>RAZEM: {{ list.totalAmount }} PLN</h2>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="addNew">Dodaj nowy</v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
    <v-flex xs12 md6 class="padding3">
      <v-card v-if="selectedRow" :loading="selectedRow.loading">
        <v-skeleton-loader
          v-if="selectedRow.loading"
          type="card, article, actions"
        >
        </v-skeleton-loader>
        <template v-else>
          <v-card-title>
            <v-text-field
              prepend-icon="mdi-form-textbox"
              label="Nazwa"
              v-model="selectedRow.data.name"
              placeholder="Wpisz nazwę..."
              ref="docName"
              :rules="validationRules.name"
            />
          </v-card-title>
          <v-card-text>
            <v-text-field
              prepend-icon="mdi-bank"
              label="Kategoria"
              v-model="selectedRow.data.category"
              placeholder="Wpisz kategorię..."
              :rules="validationRules.category"
            />
          </v-card-text>
         <v-card-text>
            <v-layout align-baseline>
              <v-flex xs4>
                <v-text-field
                  label="Liczba jednostek"
                  v-model="selectedRow.data.unitsCount"
                  placeholder="Wpisz liczbę jednostek"
                  type="number"
                />
              </v-flex>
              <v-flex xs4>
                <v-text-field
                  label="Cena jednostkowa"
                  v-model="selectedRow.data.unitPrice"
                  placeholder="Wpisz cenę jednostkową"
                  type="number"
                />
              </v-flex>
              <v-flex xs4>
                <h3>
                  <v-icon> mdi-cash-multiple </v-icon>
                  {{ amountCalculated }} PLN
                </h3>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-text>
              <span class="marginRight">
                  Utworzono: <b>{{selectedRow.data.createdPretty}}</b>
              </span>
              <span class="marginRight">
                  Edytowano: <b>{{selectedRow.data.updatedPretty}}</b>
              </span>
          </v-card-text>
        </template>
        <v-card-actions>
          <v-btn color="primary" @click="save" :loading="selectedRow.saving">
            Zapisz
          </v-btn>
          <v-btn color="error" @click="remove" :loading="selectedRow.saving">
            Usuń
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</template>
<script>
import api from "../api";
import Vue from "vue";
import rules from "../helpers/componentRules";

export default {
  data() {
    return {
      onlyActive: true,
      list: {
        loading: true,
        total: 0,
        totalAmount: null,
        headers: [
          { text: "Nazwa", value: "name" },
          { text: "Kategoria", value: "category" },
          { text: "Środki", value: "amount", align: "end" },
        ],
        items: [],
      },
      selectedRow: null,
      validationRules: {
        name: [rules.notEmpty, rules.maxNlength(40)],
        category: [rules.notEmpty, rules.maxNlength(40)],
      },
    };
  },
  watch: {
    selectedRow() {
      if (this.selectedRow && this.selectedRow.data) {
        Vue.nextTick(() => {
          this.$refs.docName.focus();
        });
      }
    },
  },
  computed: {
      amountCalculated() {
      if (!this.selectedRow.data) {
        return null;
      }
      return (
        Math.round(
          this.selectedRow.data.unitsCount *
            this.selectedRow.data.unitPrice *
            100
        ) / 100
      );
    }
  },
  async mounted() {
    await this.getList();
  },
  methods: {
    async getList() {
      this.list.loading = true;
      try {
        const response = await api.assets.getList()
        this.list.items = response.data
         const total = this.list.items.reduce((prev, current) => {
          return { amount: prev.amount + current.amount }
        }, { amount: 0 })
        this.list.totalAmount = new Intl.NumberFormat('pl-PL', { maximumSignigicantDigits: 2 }).format(total.amount)
      } catch (err) {
        console.error(err);
        this.$toastr.e("Nie udało się pobrać listy")
      } finally {
        this.list.loading = false;
      }
    },
    async selectRow(row) {
      this.selectedRow = {
        loading: true,
      };
      try {
        const response = await api.assets.getById(row.id);
        this.selectedRow = { data: response.data, loading: false };
      } catch (e) {
        console.log(e);
        this.$toastr.e("Nie udało się pobrać dokumentu z serwera");
        this.selectedRow = null;
      }
    },
    addNew() {
      this.selectedRow = {
        loading: false,
        data: {
          id: 0,
          name: "",
          category: "",
          unitsCount: 0,
          unitPrice: 0
        },
      };
    },
    async save() {
      this.selectedRow.saving = true;
      try {
        await api.assets.save(this.selectedRow.data);
        this.$toastr.s("Zapisano pomyślnie");
        this.selectedRow = null;
        this.getList();
      } catch (e) {
        console.error(e);
        this.$toastr.e("Nie udało się zapisać");
      }
    },
    async remove() {
      if (await this.$confirm("Czy na pewno chcesz usunąć?")) {
        this.selectedRow.saving = true;
        try {
          await api.assets.delete(this.selectedRow.data.id);
          this.$toastr.s("Usunięto pomyślnie");
          this.selectedRow = null;
          this.getList();
        } catch (e) {
          console.error(e);
          this.$toastr.e("Nie udało się usunąć");
        }
      }
    }
  },
};
</script>