<template>
  <v-progress-linear v-if="loading"></v-progress-linear>
  <div v-else>
    <v-card>
      <v-card-title>
        Hej {{username}}, dzień dobry!
      </v-card-title>
    </v-card>
    <v-list>
      <v-list-item v-for="menuItem in menuItems" :key="menuItem.title">
        <v-list-item-avatar>
          <v-icon>
            {{ menuItem.icon }}
          </v-icon>
        </v-list-item-avatar>
        <v-list-item-title>
          <router-link :to="menuItem.url">
            {{ menuItem.title }}
          </router-link>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import api from '@/api';
import menu from "../menu";

export default {
  data() {
    return {
      menuItems: menu,
      loading: true,
      username: null
    };
  },
  async mounted() {
    try {
      const response = await api.auth.username()
      this.loading = false
      this.username = response.data
    } catch (e) {
      console.error(e)
      this.$toastr.e('Autentykacja nieudana')
    }
  },
  methods: {
    redirect(url) {
      this.$router.push(url);
    },
  },
};
</script>