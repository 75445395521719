<template>
  <v-app>
    <v-app-bar app color="primary" dark>
        <a href="/">
          <h1 class="hidden-sm-and-down">Mac Finance Manager</h1>
          <h4 class="hidden-md-and-up">Mac Finance Manager</h4>
        </a>
      <v-spacer></v-spacer>
      <v-toolbar-items class="hidden-sm-and-down" >
        <v-btn @click="() => goTo(menuItem.url)" icon v-for="menuItem in menuItems" :key="menuItem.title">
          <v-icon :title="menuItem.title">
            {{ menuItem.icon }}
          </v-icon>
        </v-btn>
      </v-toolbar-items>
      <v-menu >
        <template  v-slot:activator="{ on, attrs }">
          <v-btn class="hidden-md-and-up" dark icon v-bind="attrs" v-on="on">
            <v-icon>mdi-menu</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="menuItem in menuItems" :key="menuItem.title">
            <v-list-item-icon>
              <v-icon>{{ menuItem.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                <router-link :to="menuItem.url">
                  {{ menuItem.title }}
                </router-link>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>

      
    </v-app-bar>

    <v-main id="main">
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<style scoped>
#main {
  margin:2%;
}
h1,h4 {
  font-family: 'ui-monospace';
  text-decoration: none;
  color: white;
}
</style>
<script>

import menu from './menu'

export default {
  data() {
    return {
      menuItems: menu
    }
  },
  methods: {
    goTo(url) {
      this.$router.push(url)
    }
  }
}
</script>

