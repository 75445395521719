<template>
  <v-layout align-start wrap>
    <v-flex xs12 md6 class="margin">
      <v-card>
        <v-card-title> Wyszukiwanie </v-card-title>
        <v-card-text>
          <v-autocomplete
            v-model="model"
            :items="entries"
            :loading="isLoading"
            :search-input.sync="search"
            hide-no-data
            hide-selected
            item-text="name"
            item-value="name"
            label="Hasła"
            placeholder="Zacznij wpisywać... (3 znaki)"
            prepend-icon="mdi-database-search"
            return-object
          >
          </v-autocomplete>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="add"> Dodaj </v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
    <v-flex xs12 md6 class="margin">
      <v-expand-transition>
        <v-card v-if="selected">
          <div v-if="editMode">
            <v-card-title>
              <v-text-field
                placeholder="Nazwa"
                v-model="editMode.item.name"
                :rules="validationRules"
              ></v-text-field>
            </v-card-title>
            <v-card-text>
              <v-layout align-baseline>
                <v-flex>
                  <v-text-field
                    placeholder="Sekret"
                    v-model="editMode.item.pwd"
                    :rules="validationRules"
                  ></v-text-field>
                </v-flex>
                <v-flex>
                  <v-btn
                    class="margin3"
                    v-if="!editMode.item.encrypted"
                    small
                    @click="() => setEncrypted(true)"
                  >
                    <v-icon title="Zaszyfrowane"> mdi-lock-open </v-icon>
                  </v-btn>
                  <v-btn
                    color="primary"
                    class="margin3"
                    v-if="editMode.item.encrypted"
                    small
                    @click="() => setEncrypted(false)"
                  >
                    <v-icon title="Odszyfrowane"> mdi-lock </v-icon>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-card-text>
          </div>
          <div v-else>
            <v-card-title>
              {{ selected.name }}
            </v-card-title>
            <v-card-text>
              <v-layout align-baseline>
                <v-flex xs6>
                  <v-text-field
                    class="margin3"
                    readonly
                    v-model="selected.pwd"
                    ref="pwd"
                    v-on:focus="$event.target.select()"
                  ></v-text-field>
                </v-flex>
                <v-flex xs6>
                  <v-btn small>
                    <v-icon
                      class="margin3"
                      color="primary"
                      title="Kopiuj"
                      @click="copyToClipboard"
                    >
                      mdi-content-copy
                    </v-icon>
                  </v-btn>
                  <v-btn
                    class="margin3"
                    v-if="selected.encrypted"
                    small
                    @click="decrypt"
                  >
                    <v-icon
                      :color="decryptionSuccess ? 'green' : 'primary'"
                      title="Odszyfruj"
                    >
                      mdi-lock-open
                    </v-icon>
                  </v-btn>
                  <v-text-field
                    ref="decrypt"
                    class="decrypt"
                    type="number"
                    v-if="decryptionMode"
                    v-model="decryption"
                    @input="decryptChanged"
                    :loading="decryptionInProgress"
                  >
                  </v-text-field>
                </v-flex>
              </v-layout>
            </v-card-text>
          </div>
          <v-card-actions>
            <v-btn v-if="!editMode" color="primary" @click="setEditMode">
              Edytuj
            </v-btn>
            <v-btn v-if="editMode" color="secondary" @click="cancelEdit">
              Anuluj
            </v-btn>
            <v-btn
              v-if="editMode"
              color="primary"
              @click="save"
              :loading="editMode.saveInProgress"
              :disabled="editMode.saveInProgress"
            >
              Zapisz
            </v-btn>
            <v-btn
              v-if="editMode"
              color="red lighten-2"
              :loading="editMode.deleteInProgress"
              :disabled="editMode.deleteInProgress"
              @click="remove"
            >
              Usuń
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-expand-transition>
    </v-flex>
  </v-layout>
</template>

<style scoped>
.margin {
  padding: 5px;
}
.margin3 {
  margin-right: 3px;
}
.decrypt {
  width: 120px;
  display: inline-flex;
}
</style>

<script>
import api from "../api";
import { handle400 } from '../helpers/httpHelpers';

export default {
  data: () => ({
    entries: [],
    isLoading: false,
    model: null,
    search: null,
    decryptionMode: false,
    decryption: "",
    decryptionInProgress: false,
    decryptionSuccess: false,
    editMode: null,
    validationRules: [(value) => !!value || "Pole wymagane"],
  }),

  computed: {
    selected() {
      return this.model;
    },
  },

  watch: {
    search(val) {
      if (this.isLoading) return;
      if (!val || val.length < 3) return;

      clearTimeout(this._timerId);
      this._timerId = setTimeout(() => this.fetchSecrets(val), 500);
    },
    model() {
      if (!this.model || !this.model.created) {
        this.resetDecrypt();
        this.editMode = null;
      }
    },
  },

  methods: {
    async fetchSecrets(val) {
      this.isLoading = true;
      try {
        const response = await api.secrets.get(val);
        this.entries = response.data;
      } catch (e) {
        this.$toastr.e("Secrets load failed");
      } finally {
        this.isLoading = false;
      }
    },

    async fetchDecryption() {
      this.decryptionInProgress = true;
      try {
        const response = await api.secrets.decrypt(
          this.selected.id,
          this.decryption
        );
        this.selected.pwd = response.data;
        this.decryptionSuccess = true;
        this.$toastr.s("Odszyfrowano pomyślnie");
      } catch (e) {
        this.$toastr.e("Nie udało się odszyfrować");
        handle400(e, this)
      } finally {
        this.decryptionInProgress = false;
      }
    },

    decrypt() {
      this.decryptionMode = true;
      setTimeout(() => this.$refs.decrypt.focus(), 500);
    },

    decryptChanged() {
      clearTimeout(this._decryptTimer);
      this._decryptTimer = setTimeout(this.fetchDecryption, 4000);
    },

    resetDecrypt() {
      this.decryption = "";
      this.decryptionMode = false;
      this.decryptionInProgress = false;
      this.decryptionSuccess = false;
    },

    copyToClipboard() {
      this.$refs.pwd.focus()
      document.execCommand('copy')
      this.$toastr.s("Skopiowano")
    },

    setEditMode() {
      const item = {
        name: this.model.name,
        pwd: this.model.pwd,
        encrypted: this.model.encrypted,
        created: this.model.created,
        id: this.model.id
      };

      this.editMode = {
        item: item,
        saveInProgress: false,
        deleteInProgress: false,
      };
    },

    cancelEdit() {
      this.editMode = null;
    },

    setEncrypted(flag) {
      this.editMode.item.encrypted = flag;
    },

    async save() {
      this.editMode.saveInProgress = true;
      try {
        await api.secrets.save(this.editMode.item);
        this.$toastr.s("Zapisano pomyślnie");
        this.model = null;
      } catch (error) {
        this.$toastr.e("Nie udało się zapisać");
        handle400(error, this)
      } finally {
        this.editMode.saveInProgress = false;
      }
    },

    async remove() {
      const res = await this.$confirm("Na pewno chcesz usunąć ten wpis?");
      if (res) {
        this.editMode.deleteInProgress = true;
        try {
          if (!this.selected.created) {
            await api.secrets.delete(this.selected.id);
          }
          this.$toastr.s("Usunięto");
          this.model = null;
        } catch (e) {
          this.$toastr.e("Nie udało się usunąć");
          handle400(e, this)
        } finally {
          this.editMode.deleteInProgress = false;
        }
      }
    },

    add() {
      const item = {
        name: "",
        pwd: "",
        encrypted: false,
        created: true,
      };
      this.model = item
      this.setEditMode()
    },
  },
};
</script>
