<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="date"
        :label="label"
        prepend-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker :value="date" @input="menu = false" @change="(evt) => $emit('changed', evt)"></v-date-picker>
  </v-menu>
</template>
<script>
export default {
    props: {
        date: String,
        label: String
    },
    data() {
        return {
            menu: false
        }
    }
}
</script>
