<template>
    <v-card>
        <v-card-title>
            Wymagane logowanie
        </v-card-title>
        <v-card-text class="center">
            <v-btn large color="primary" :href="url">
                Login by Google
            </v-btn>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    data() {
        return {
            url: `${process.env.VUE_APP_API_URL}/auth`
        }
    }
}
</script>
