<template>
  <v-layout align-start wrap>
    <v-flex xs12 md6 class="padding">
      <v-card>
        <v-card-title>Podsumowanie</v-card-title>
        <v-card-text v-if="current.time">
          {{ current.time }}
        </v-card-text>
        <v-skeleton-loader v-if="current.loading" type="card, article, actions">
        </v-skeleton-loader>
        <v-treeview
          v-else
          :items="current.root"
          rounded
          item-key="id"
          item-text=""
        >
          <template v-slot:label="{ item }">
            <div class="flex-space-between">
              <span>
                {{ item.title }} {{ item.count > 1 ? `(${item.count})` : "" }}
              </span>
              <span>
                {{ formatAmount(item.amount) }}
              </span>
            </div>
          </template>
        </v-treeview>
        <v-card-actions>
          <v-btn
            color="primary"
            v-if="current.time == null"
            @click="makeSnapshot"
          >
            Zrób zrzut
          </v-btn>
          <template v-else>
            <v-btn color="secondary" @click="getCurrent"
              >Powrót do bieżącego</v-btn
            >
            <v-btn color="red" @click="remove">Usuń</v-btn>
          </template>
        </v-card-actions>
      </v-card>
    </v-flex>
    <v-flex xs12 md6 class="padding">
      <v-card>
        <v-card-title> Starsze zrzuty </v-card-title>
        <v-card-text>
          <v-layout>
            <v-flex>
              <date-picker
                :date="filter.from"
                label="Od"
                @changed="(value) => filterChanged('from', value)"
              >
              </date-picker>
            </v-flex>
            <v-flex>
              <date-picker
                :date="filter.to"
                label="Do"
                @changed="(value) => filterChanged('to', value)"
              >
              </date-picker>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-text>
          <v-data-table
            :headers="snapshots.headers"
            :loading="snapshots.loading"
            :items="snapshots.items"
            disable-sort
            class="elevation-1"
            :server-items-length="snapshots.total"
            :options.sync="listOptions"
            @click:row="selectRow"
          ></v-data-table>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<style scoped>
.flex-space-between {
  display: flex;
  justify-content: space-between;
}
.padding {
  padding: 3px;
}
</style>

<script>
import api from "@/api";
import numberFormatter from "../helpers/numberFormatter";
import DatePicker from "./DatePicker.vue";

const currentDate = new Date();
const prevDate = new Date();
prevDate.setFullYear(prevDate.getFullYear() - 1);

export default {
  components: { DatePicker },
  data() {
    return {
      current: {
        id: null,
        time: null,
        loading: true,
        root: null,
        error: null,
      },
      filter: {
        from: prevDate.toISOString().substr(0, 10),
        to: currentDate.toISOString().substr(0, 10),
      },
      snapshots: {
        loading: true,
        total: 0,
        items: [],
        headers: [{ text: "Data zrobienia zrzutu", value: "time" }],
      },
      listOptions: {},
    };
  },
  methods: {
    formatAmount(value) {
      return numberFormatter.formatAmount(value) + " PLN";
    },
    filterChanged(key, value) {
      this.filter[key] = value
      this.getList()
    },
    async getList() {
      this.snapshots.loading = true;
      try {
        const response = await api.summary.snapshots({
          ...this.filter,
          page: this.listOptions.page,
          count: this.listOptions.itemsPerPage,
        });
        const data = response.data;
        this.snapshots.total = data.total;
        this.snapshots.items = data.items;
      } catch (e) {
        this.$toastr.e("Nie udało się pobrać elementów z listy");
        console.log(e);
      } finally {
        this.snapshots.loading = false;
      }
    },
    async selectRow(row) {
      this.current.loading = true;
      try {
        const response = await api.summary.getById(row.id);
        const data = response.data;
        this.current.id = row.id;
        this.current.time = data.time;
        this.current.root = [ data.data ];
        this.current.error = null;
      } catch (e) {
        this.$toastr.e("Nie udało się pobrać danych");
        this.current.error = e.toString();
        console.error(e);
      } finally {
        this.current.loading = false;
      }
    },
    async makeSnapshot() {
      this.current.loading = true;
      try {
        await api.summary.create(this.current.root);
        this.$toastr.s("Pomyślnie wykonano zrzut");
        this.getList();
      } catch (e) {
        console.error(e);
        this.$toastr.e("Nie udało się wykonać zrzutu");
      } finally {
        this.current.loading = false;
      }
    },
    async getCurrent() {
      try {
        const response = await api.summary.current();
        this.current.id = null;
        this.current.time = null;
        this.current.error = null;
        this.current.root = response.data;
      } catch (e) {
        console.error(e);
        this.current.error = "Nie udało się załadować bieżącego stanu";
      } finally {
        this.current.loading = false;
      }
    },
    async remove() {
      if (await this.$confirm("Czu na pewno chcesz usunąć ten zrzut?")) {
        this.current.loading = true;
        try {
          await api.summary.delete(this.current.id);
          this.$toastr.s("Pomyślnie usunięto")
          this.getCurrent()
          this.getList()
        } catch (e) {
          console.error(e);
          this.$toastr.e("Nie udało się usunąć")
        } finally {
          this.current.loading = false;
        }
      }
    },
  },
  mounted() {
    this.getCurrent();
    this.getList();
  },
  watch: {
    listOptions: {
      handler() {
        this.getList();
      },
      deep: true,
    },
  },
};
</script>
